import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    { path: '/:lang/about', component: () => import('../components/ContentAbout.vue') },
    { path: '/:lang/articles', component: () => import('../components/ContentArticles.vue') },
    { path: '/:lang/contact', component: () => import('../components/ContentContact.vue') },
    { path: '/:lang/home', component: () => import('../components/ContentHome.vue') },
    { path: '/:lang/imprint', component: () => import('../components/ContentImprint.vue') },
    { path: '/:lang/privacy', component: () => import('../components/ContentPrivacy.vue') },
    { path: '/:lang/projects', component: () => import('../components/ContentProjects.vue') },
    { path: '/:lang/terms', component: () => import('../components/ContentTerms.vue') },
    { path: '/:lang/articles/2023-12-10-taylor-series', component: () => import('../components/articles/2023-12-10-taylor-series.vue') },
    { path: '/:lang/articles/2023-12-05-fourier-series', component: () => import('../components/articles/2023-12-05-fourier-series.vue') },
    { path: '/:lang/articles/2023-12-30-ssh-auth', component: () => import('../components/articles/2023-12-30-ssh-auth.vue') },
    { path: '/:lang/articles/2024-01-20-ftp-setup', component: () => import('../components/articles/2024-01-20-ftp-setup.vue') },
    { path: '/:lang/projects/2023-11-10-gittycat', component: () => import('../components/projects/2023-11-10-gittycat.vue') },
    { path: '/:lang/projects/2024-01-02-hasher', component: () => import('../components/projects/2024-01-02-hasher.vue') },
    { path: '/:lang/projects/2024-07-14-it-security-and-safety', component: () => import('../components/projects/2024-07-14-it-security-and-safety.vue') },

    // Redirect any unmatched routes to home with default language
    { path: '/:pathMatch(.*)*', redirect: '/de/home' },
  ],
  scrollBehavior(to, from, savedPosition) {
    // If a saved position is available, use it (e.g., when using the browser's back button)
    if (savedPosition) {
      return savedPosition;
    }
    // Otherwise, scroll to the top of the page
    return { top: 0 };
  },
});

export default router;
